import React from "react";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GatsbyImage from "../components/Portfolio/index";

const Portofolio = props => {
    const images = props.data.allFile.edges.map(
        ({ node }) => node.childImageSharp
    );
    console.log(images);
    return (
        <Layout>
            <SEO title="Portofolio" />
            <section id={"galeri"} className={"galeri"}>
                <Container>
                    <div className={"section-title"}>
                        <h2>Portofolio</h2>
                        <p>
                            Portofolio kami telah tersebar di penjuru Indonesia.
                            Sesuai dengan visi kami &quot;Providing the Best
                            Solution&quot; semua klien CV. Anugerah Terang Dunia
                            puas dengan hasil pekerjaan yang telah dilakukan.
                        </p>
                        <p>
                            Berikut adalah hasil pekerjaan kami meliputi layar
                            LED (videotron), <i>running text</i>, dan media
                            periklanan lainnya.
                        </p>
                        <GatsbyImage images={images} />
                    </div>
                </Container>
            </section>
        </Layout>
    );
};

export default Portofolio;

export const query = graphql`
    fragment fragmen on File {
        childImageSharp {
            thumb: fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    query GalleryQuery {
        allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
            edges {
                node {
                    ...fragmen
                }
            }
        }
    }
`;
